import { Accordion, Form, InputGroup, Row } from "react-bootstrap";
import DynamicDrinksList from "./DynamicDrinkList";
import { item } from "./types";
import { useEffect, useState } from "react";
import Papa from "papaparse";
import debounce from "debounce";
import DynamicSpiritsList from "./DynamicSpiritsList";

const convertToDrinkArr = (parsedResult: Papa.ParseResult<any>): item[] => {
  let result: item[] = [];
  parsedResult.data.shift();
  parsedResult.data.map((row) => {
    let item: item = {
      menuNum: row[0],
      title: row[1],
      sponsor: row[2],
      notes: row[3],
      desc: row[4],
      brewery: row[5],
      abv: row[6],
      type: row[7],
      location: row[8],
    };
    result.push(item);
  });
  return result;
};

const DrinksList = () => {
  const [items, setItems] = useState<item[]>([]);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    fetch("/data/items.csv")
      .then((response) => response.text())
      .then((responseText) => {
        const parsedData = Papa.parse<any>(responseText);
        let items = convertToDrinkArr(parsedData);
        setItems(items);
      });
  }, []);

  const handleSearch = (value: string) => {
    window.scrollTo(0, 0);
    setSearch(value);
  };

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Drinks List</h2>
      <div className="filterForm sticky-top">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <InputGroup>
            <InputGroup.Text className="filterLabel">Search:</InputGroup.Text>
            <Form.Control
              className="filterField"
              placeholder="Type GF for Gluten Free"
              onChange={debounce((e) => handleSearch(e.target.value), 500)}
              onSubmit={() => {}}
            ></Form.Control>
            {/*                            <InputGroup.Text className="filterLabel">Sort By: </InputGroup.Text>
              <Form.Select className="filterField" onChange={(e) => setSortBy(e.target.value)}>
                <option value="">No.</option>
                <option value="abc">Alphabetical</option>
                <option value="abv">ABV %</option>
              </Form.Select>  */}
          </InputGroup>
        </Form>
      </div>
      <Accordion flush alwaysOpen>
        <DynamicDrinksList
          items={items.filter((item) => {
            let match = false;
            const searchLower = search.toLocaleLowerCase();
            if (item.location != "Downstairs") {
              return match;
            }
            if (
              item.title.toLowerCase().includes(searchLower) ||
              item.brewery.toLowerCase().includes(searchLower) ||
              item.desc.toLowerCase().includes(searchLower)
            ) {
              match = true;
            }
            return match;
          })}
          name={`Downstairs Bar [${
            items.filter((item) => {
              let match = false;
              const searchLower = search.toLocaleLowerCase();
              if (item.location != "Downstairs") {
                return match;
              }
              if (
                item.title.toLowerCase().includes(searchLower) ||
                item.brewery.toLowerCase().includes(searchLower) ||
                item.desc.toLowerCase().includes(searchLower)
              ) {
                match = true;
              }
              return match;
            }).length
          }]`}
          accoridanKey="0"
        />
        <DynamicDrinksList
          items={items.filter((item) => {
            let match = false;
            const searchLower = search.toLocaleLowerCase();
            if (item.location != "Upstairs") {
              return match;
            }
            if (
              item.title.toLowerCase().includes(searchLower) ||
              item.brewery.toLowerCase().includes(searchLower) ||
              item.desc.toLowerCase().includes(searchLower)
            ) {
              match = true;
            }
            return match;
          })}
          name={`Upstairs Bar [${
            items.filter((item) => {
              let match = false;
              const searchLower = search.toLocaleLowerCase();
              if (item.location != "Upstairs") {
                return match;
              }
              if (
                item.title.toLowerCase().includes(searchLower) ||
                item.brewery.toLowerCase().includes(searchLower) ||
                item.desc.toLowerCase().includes(searchLower)
              ) {
                match = true;
              }
              return match;
            }).length
          }]`}
          accoridanKey="1"
        />
        <DynamicDrinksList
          items={items.filter((item) => {
            let match = false;
            const searchLower = search.toLocaleLowerCase();
            if (item.location != "Spirits Bar (Cider)") {
              return match;
            }
            if (
              item.title.toLowerCase().includes(searchLower) ||
              item.brewery.toLowerCase().includes(searchLower) ||
              item.desc.toLowerCase().includes(searchLower)
            ) {
              match = true;
            }
            return match;
          })}
          name={`Chairman's Spirits Bar (Cider) [${
            items.filter((item) => {
              let match = false;
              const searchLower = search.toLocaleLowerCase();
              if (item.location != "Spirits Bar (Cider)") {
                return match;
              }
              if (
                item.title.toLowerCase().includes(searchLower) ||
                item.brewery.toLowerCase().includes(searchLower) ||
                item.desc.toLowerCase().includes(searchLower)
              ) {
                match = true;
              }
              return match;
            }).length
          }]`}
          accoridanKey="2"
        />
        <DynamicSpiritsList
          items={items.filter((item) => {
            let match = false;
            const searchLower = search.toLocaleLowerCase();
            if (item.location != "Spirits Bar") {
              return match;
            }
            if (
              item.title.toLowerCase().includes(searchLower) ||
              item.brewery.toLowerCase().includes(searchLower) ||
              item.desc.toLowerCase().includes(searchLower)
            ) {
              match = true;
            }
            return match;
          })}
          name={`Chairmans Spirits Bar [${
            items.filter((item) => {
              let match = false;
              const searchLower = search.toLocaleLowerCase();
              if (item.location != "Spirits Bar") {
                return match;
              }
              if (
                item.title.toLowerCase().includes(searchLower) ||
                item.brewery.toLowerCase().includes(searchLower) ||
                item.desc.toLowerCase().includes(searchLower)
              ) {
                match = true;
              }
              return match;
            }).length
          }]`}
          accoridanKey="3"
        />
      </Accordion>
    </div>
  );
};

export default DrinksList;
