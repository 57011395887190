import { useEffect, useState } from "react";
import { Button, Form, Modal, Image, Row, Col } from "react-bootstrap";

const Welcome = () => {
  const [show, setShow] = useState(true);
  const [dontShowCk, setDontShowCk] = useState(false);

  useEffect(() => {
    setShow(localStorage.getItem("hideWelcomeMsg") !== "true");
  }, []);

  const handleClose = () => {
    setShow(false);
    if (dontShowCk) {
      localStorage.setItem("hideWelcomeMsg", "true");
    }
  };

  const handleShow = () => setShow(true);

  return (
    <div>
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>A Warm Welcome From Our Chairman</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={{ paddingTop: "10px", paddingBottom: "5px" }}>
            <span style={{ fontSize: "19px" }}>
              To the Supporters and Attendees of the Beer Festival,
            </span>
            <br />
            <br />
            A very warm welcome to the 2024 addition of the Cockermouth Round
            Table Beer Festival! We are delighted to have your company for this
            highlight event in our town, with all proceeds going to local good
            causes.
            <br />
            <br />
            This year we have donated to a variety of projects including the Air
            Ambulance, Cockermouth Park Run which had its inaugural event
            earlier this month, Fairfield school sensory room project, an
            international trip for a local youth boxer who went on to win a gold
            medal, laptops for the Food Bank, kit for Cockermouth Swimming Club
            and some of the boys and girls age groups at Cockermouth Juniors
            Football Club, training costs for Cockermouth First Responders,
            Cumbria Women’s Rugby team as they reached a national final at
            Twickenham, and many more.
            <br />
            <br />
            Without the generosity of our sponsors, the volume of donations just
            wouldn’t be possible. We are very grateful to have Cumbrian Ales as
            the main event sponsor once again this year. You will also see a
            number of new and returning beer sponsors this year, and I look
            forward to sharing a beer or two with you in thanks. A special
            mention should go to Tractor Shed Brewery for their year-round
            support of Cockermouth Round Table, and we wish former Tabler and
            owner, Graeme Mitchell all the best in his upcoming brewery
            retirement.
            <br />
            <br />
            Cockermouth Round Table is not just about beer! We are a young man’s
            club for 18-45 year old males and have had a great social calendar
            this year. This has helped support a growing number of men who want
            to Do More, meet new people and have new experiences. Round Table is
            part of a Family of clubs for all; it was a pleasure to attend the
            inauguration of our newest club West Cumbria XRT last weekend. This
            club is aimed at the active 40+ male, along with Cockermouth 41
            Club, who celebrated a big golden birthday last week too. Many of
            the members of these three great clubs will be serving your drinks
            this week. New members ALWAYS welcome; please see someone in an
            orange T shirt if you are interested or find us on Facebook.
            <br />
            <br />
            We trust that you will enjoy good company and good community spirit
            across this beer festival week, which makes this great town the
            place it is. I for one, look forward to sampling the great beer on
            offer and hope this puts a smile on your face too!
            <br />
            <br />
            Cheers!
            <br />
            <br />
            <Image
              src="/assets/signature.png"
              style={{ maxHeight: "50px", paddingBottom: "5px" }}
            />
          </div>
          <Row style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <Col>
              <Image
                src="/assets/Cockermouth RT Logo.jpg"
                fluid
                style={{ maxHeight: "115px", marginLeft: "20px" }}
                className="modalImg"
              />
            </Col>
            <Col>
              <Image
                src="/assets/cralogo.png"
                fluid
                style={{ maxHeight: "115px", marginRight: "20px" }}
                className="modalImgCRA"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="close-welcome-msg"
          >
            Bring on the beer!
          </Button>
          <Form.Check
            type="switch"
            label="Don't show this again"
            defaultChecked={dontShowCk}
            onChange={() => setDontShowCk(!dontShowCk)}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Welcome;
