import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { item } from "./types";

type props = {
  drink: item;
  key: number;
};

const DrinksListItem = (props: props) => {
  const sponsorImgSrc = `assets/sponsorImg/${props.drink.menuNum}.png`;
  const imgPath = `/assets/drinkImg/${props.drink.menuNum}.png`;

  return (
    <Card>
      <Container>
        <Row>
          <div>
            <h2>
              {props.drink.menuNum}. {props.drink.title}
            </h2>
          </div>
        </Row>
        <Row>
          <Col>
            <Image
              src={imgPath}
              style={{
                maxHeight: "150px",
                borderRadius: "10px",
                padding: "8px",
              }}
            />
          </Col>
          <Col xs="auto">
            {props.drink.sponsor ? (
              <>
                <Card style={{ backgroundColor: "#777777", textAlign: "center" }}>
                  <span>
                    Sponsored by:
                    <br />
                  </span>
                  <Image
                    src={sponsorImgSrc}
                    style={{
                      maxHeight: "110px",
                      borderRadius: "10px",
                      padding: "3px",
                    }}
                  />
                  {props.drink.sponsor == "Maryport Round Table" ? "Maryport" : <></>}
                  {props.drink.sponsor == "Maryport Round Table" ? <br/> : <></>}
                  {props.drink.sponsor == "Maryport Round Table" ? "Round Table" : <></>}
                  {props.drink.sponsor == "Cockermouth 41 Club" ? "Cockermouth" : <></>}
                  {props.drink.sponsor == "Cockermouth 41 Club" ? <br/> : <></>}
                  {props.drink.sponsor == "Cockermouth 41 Club" ? "41 Club" : <></>}
                </Card>
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="auto">
            <span >
              <b>Brewery:</b> {props.drink.brewery}
            </span>
            {/*<div>Thankyou to {props.drink.sponsor} for sponsoring!</div>*/}
            <div style={{ paddingTop: "5px", paddingBottom: "5px", borderTop: "1px solid #777777" }}>
              <b>Type:</b> {props.drink.type}{" "}
              <b style={{ paddingLeft: "20px" }}>ABV:</b> {props.drink.abv}
              <br />
            </div>
            <div style={{ paddingTop: "5px", paddingBottom: "5px", borderTop: "1px solid #777777" }}>
              <b>Description:</b> {props.drink.desc}
            </div>
          </Col>
          <Col xs="auto">
            {/*
            <Image
              src="assets/YourLogoHere.jpg"
              fluid
              style={{
                maxHeight: "200px",
                borderRadius: "10px",
                padding: "8px",
              }}
            /> */}
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default DrinksListItem;
