import "bootstrap/dist/css/bootstrap.min.css";
import DrinksList from "./DrinksList/DrinksList";
import Header from "./header";
import Footer from "./footer";
import "./App.css";
import Welcome from "./welcome";
import Filters from "./filters";

function App() {
  return (
    <div>
      <Welcome />
      <Header />
      <DrinksList />
      <Footer />
    </div>
  );
}

export default App;
