import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { item } from "./types";

type props = {
  drink: item;
  key: number;

};

const DrinksListItem = (props: props) => {
  const sponsorImgSrc = `assets/sponsorImg${props.drink.sponsor}.png`;
  const imgPath = `/assets/drinkImg/${props.drink.menuNum}.png`;

  return (
    <Card>
      <Container>
        <Row>
          <Col xs="auto">
            <div>
              <h2>
                {props.drink.menuNum}. {props.drink.title}
              </h2>
              {props.drink.desc}
            </div>
          </Col>
          <Col xs="auto">
          </Col>
          <Col xs="auto">
            {/*
            <Image
              src="assets/YourLogoHere.jpg"
              fluid
              style={{
                maxHeight: "200px",
                borderRadius: "10px",
                padding: "8px",
              }}
            /> */}
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default DrinksListItem;
